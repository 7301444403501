import classnames from 'classnames/bind'
import React from 'react'

import Slider from '@unlikelystudio/react-slider'

import GoldenTitle from '~/components/GoldenTitle'
import { LinkProps } from '~/components/Link'
import Post from '~/components/RelatedPosts/Post'
import { RichTextBlock } from '~/components/RichText'
import SquaredCta, { ButtonThemes } from '~/components/SquaredCta'

import { useStyle } from '~/providers/StyleProvider'

import { ArticlePushProps } from '~/data/serialize-article-push'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface RelatedPostsProps {
  className?: string
  title?: RichTextBlock[]
  link?: LinkProps
  linksLabel?: string
  items?: ArticlePushProps[]
}

function RelatedPosts({
  className,
  title,
  link,
  linksLabel,
  items,
}: RelatedPostsProps) {
  const gridStyle = useStyle({ grid: 'base-grid' })

  return (
    <section className={cx(css.RelatedPosts, className)}>
      <div className={gridStyle}>
        {title && <GoldenTitle className={css.title} title={title} />}
        <div className={cx(css.hideOnSmallScreen, css.posts)}>
          {items?.map((item, index) => (
            <Post
              key={`relatedPost${index}`}
              className={css.post}
              linksLabel={linksLabel}
              {...item}
            />
          ))}
        </div>
      </div>
      <Slider className={cx(css.hideOnLargeScreen, css.slider)} infinite snap>
        {items.map((item, index) => (
          <Post
            key={`relatedPostSlider${index}`}
            className={css.post}
            linksLabel={linksLabel}
            {...item}
          />
        ))}
      </Slider>
      <div className={cx(css.hideOnLargeScreen, css.buttonWrapper)}>
        <SquaredCta
          className={css.button}
          theme={ButtonThemes.Transparent}
          {...link}
        />
      </div>
    </section>
  )
}

RelatedPosts.defaultProps = {}

export default RelatedPosts
