import { ErrorOption } from 'react-hook-form'
import { useMutation, UseMutationOptions } from 'react-query'
import { submitDynamicForm } from '~/lib/handle-api'

import useOnMutationError from '~/hooks/account/useOnMutationError'
import useAlerts from '~/hooks/useAlerts'
import usePrismicLocale from '~/hooks/usePrismicLocale'

interface DynamicFormInput {
  [key: string]: string
}

export default function useDynamicForm(
  mutationOptions?: UseMutationOptions<any, any, any>,
  setError?: (name?: string, error?: ErrorOption) => void,
) {
  const triggerAlert = useAlerts()
  const lang = usePrismicLocale()
  const onMutationError = useOnMutationError(setError)

  return useMutation<any, any, any>(
    async (fields: DynamicFormInput) => {
      const payload = Object.keys(fields)?.reduce((acc, name) => {
        if (fields?.[name] && typeof fields?.[name] === 'string') {
          return {
            ...acc,
            [name]: fields[name],
          }
        }

        return acc
      }, {})

      return await submitDynamicForm({ payload: { ...payload, lang } })
    },
    {
      ...mutationOptions,
      onSuccess: (data, variables, context) => {
        if (!data?.success) {
          return triggerAlert('JEM_SERVER_ERRROR')
        }

        mutationOptions?.onSuccess(data, variables, context)
      },
      onError: (err, ...rest) => {
        onMutationError(err, rest, mutationOptions?.onError)
      },
    },
  )
}
