import classnames from 'classnames/bind'
import React from 'react'

import { useIsHover } from '@unlikelystudio/react-hooks'

import ImageWithPlaceholder, {
  ImageProps,
} from '~/components/ImageWithPlaceholder'
import InlineCta from '~/components/InlineCta'
import Link, { LinkProps } from '~/components/Link'
import Ratio from '~/components/Ratio'
import RichText, {
  prismicRichTextAsText,
  RichTextBlock,
} from '~/components/RichText'

import { useStyle } from '~/providers/StyleProvider'

import getAriaLabelProp from '~/utils/get-aria-label-prop'

import css from './styles.module.scss'

const cx = classnames.bind(css)

interface ReboundItemProps {
  title?: RichTextBlock[]
  link?: LinkProps
  image?: ImageProps
}
export interface ReboundZoneEditoProps {
  className?: string
  items?: ReboundItemProps[]
}

const DEFAULT_ALT = 'Reassurance zone edito image'

function ReboundZoneEdito({ className, items }: ReboundZoneEditoProps) {
  const [isHover, mouseEvents] = useIsHover()
  const gridStyle = useStyle({ grid: 'base-grid' })
  const textStyle = useStyle({ textPreset: 'text-18' })
  const ratio = 545 / 307

  return (
    <section className={cx(css.ReboundZoneEdito, className, gridStyle)}>
      {items?.map((item, index) => (
        <div
          className={cx(css.item, { isHover })}
          key={`reboundItem${index}`}
          {...mouseEvents}>
          {item?.image && (
            <Link
              {...getAriaLabelProp(item?.link?.children as string)}
              {...item.link}>
              <Ratio ratio={ratio} className={css.imageWrapper}>
                {(cn) => (
                  <>
                    <ImageWithPlaceholder
                      className={cn}
                      layout="fill"
                      objectFit="cover"
                      {...item.image}
                      sizesFromBreakpoints={[
                        { breakpoint: 'md', columns: 5 },
                        { columns: 6 },
                      ]}
                      alt={
                        item?.image?.alt ??
                        prismicRichTextAsText(item?.title) ??
                        DEFAULT_ALT
                      }
                    />
                  </>
                )}
              </Ratio>
            </Link>
          )}
          {item?.title && (
            <RichText
              className={cx(textStyle, css.title)}
              render={item.title}
            />
          )}
          {item?.link && (
            <InlineCta
              className={css.linkRight}
              lineProps={{ theme: 'gray', initialLineState: 'underlined' }}
              {...item?.link}
            />
          )}
        </div>
      ))}
    </section>
  )
}

ReboundZoneEdito.defaultProps = {}

export default ReboundZoneEdito
