import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import React, { useRef } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslate } from 'react-polyglot'

import Email from '~/components/Account/Forms/Email'
import Phone from '~/components/Account/Forms/Phone'
import Input from '~/components/Form/Input'
import ImageWithPlaceholder, {
  ImageProps,
} from '~/components/ImageWithPlaceholder'
import Ratio from '~/components/Ratio'
import RichText, { RichTextBlock } from '~/components/RichText'
import SquaredCta from '~/components/SquaredCta'

import { useStyle } from '~/providers/StyleProvider'

import useAlerts from '~/hooks/useAlerts'
import useDynamicForm from '~/hooks/useDynamicForm'
import useFormWithMutation from '~/hooks/useFormWithMutation'
import useStickyContent from '~/hooks/useStickyContent'

import css from './styles.module.scss'

const cx = classnames.bind(css)

enum FormSliceFieldTypes {
  Text = 'text',
  Email = 'email',
  Tel = 'tel',
  Textarea = 'textarea',
}

interface FormSliceFieldProps {
  fullWidth?: boolean
  required?: boolean
  name?: string
  placeholder?: string
  type?: FormSliceFieldTypes
}

export interface FormSliceProps {
  className?: string
  title?: RichTextBlock[]
  image?: ImageProps
  object?: string
  buttonLabel?: string
  fields: FormSliceFieldProps[]
}

const DEFAULT_ALT = 'Form image'

function FormSlice({
  className,
  title,
  image,
  object,
  buttonLabel,
  fields,
}: FormSliceProps) {
  const t = useTranslate()
  const { asPath } = useRouter()
  const triggerAlert = useAlerts()
  const formWrapperRef = useRef<HTMLFormElement>(null)
  const imageWrapperRef = useRef<HTMLDivElement>(null)
  const gridStyle = useStyle({ grid: 'base-grid' })
  const titleStyle = useStyle({ textPreset: 'title-20-30' })

  const { imageTop, contentTop } = useStickyContent(
    formWrapperRef,
    imageWrapperRef,
  )

  const renderInput = (props, index) => {
    const { type, fullWidth, ...field } = props ?? {}

    const defaultProps = {
      key: `formField${index}`,
      className: cx(css.input, { fullWidth }),
      withDefaultErrorString: true,
      ...field,
    }

    switch (type) {
      case FormSliceFieldTypes.Text:
        return <Input {...defaultProps} />
      case FormSliceFieldTypes.Email:
        return <Email {...defaultProps} />
      case FormSliceFieldTypes.Textarea:
        return <Input textarea rows={5} {...defaultProps} />
      case FormSliceFieldTypes.Tel:
        return (
          <Phone
            {...defaultProps}
            name="phone"
            className={cx(defaultProps?.className, css.phone)}
          />
        )
      default:
        return null
    }
  }

  const { mutate, isLoading } = useDynamicForm(
    {
      onSuccess: () => {
        triggerAlert('JEM_FORM_SUBMIT')
        form.reset()
      },
    },
    (...params) => form.setError(...params),
  )

  const { form, onSubmit } = useFormWithMutation(mutate, null, 'dynamicForm')

  return (
    <section className={cx(css.FormSlice, className, gridStyle)}>
      <FormProvider {...form}>
        <form
          ref={formWrapperRef}
          className={css.form}
          onSubmit={onSubmit}
          style={{ top: contentTop }}>
          {title && (
            <RichText className={cx(css.title, titleStyle)} render={title} />
          )}
          <Input
            type="hidden"
            name="origin"
            value={`${process.env.NEXT_PUBLIC_ROOT_URL}${asPath}`}
          />
          <Input type="hidden" name="object" value={object} />
          {fields?.map(renderInput)}
          <SquaredCta
            className={css.submit}
            type="submit"
            withBorder
            withBackground
            isLoading={isLoading}>
            {buttonLabel ?? t('form.submit_send')}
          </SquaredCta>
        </form>
      </FormProvider>
      <div
        ref={imageWrapperRef}
        className={css.imageWrapper}
        style={{ top: imageTop }}>
        <Ratio ratio={430 / 573}>
          {(cn) => (
            <ImageWithPlaceholder
              className={cn}
              layout="fill"
              objectFit="cover"
              sizesFromBreakpoints={[
                { breakpoint: 'lg', columns: 4 },
                { columns: 5 },
              ]}
              {...image}
              alt={image?.alt ?? DEFAULT_ALT}
            />
          )}
        </Ratio>
      </div>
    </section>
  )
}

FormSlice.defaultProps = {}

export default FormSlice
