import classnames from 'classnames/bind'
import { Elements } from 'prismic-reactjs'
import React from 'react'

import GoldenTitle from '~/components/GoldenTitle'
import ImageWithPlaceholder from '~/components/ImageWithPlaceholder'
import Link from '~/components/Link'
import Ratio from '~/components/Ratio'
import RichText, {
  prismicRichTextAsText,
  RichTextBlock,
} from '~/components/RichText'
import { Store } from '~/components/Stores'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface SelectedStoresProps {
  className?: string
  title?: RichTextBlock[]
  items?: Store[]
}

const DEFAULT_ALT = 'Selected store image'

function SelectedStores({ className, title, items }: SelectedStoresProps) {
  const gridStyle = useStyle({ grid: 'base-grid' })
  const titleStoreStyle = useStyle({ textPreset: 'title-13-20' })
  const textStoreStyle = useStyle({ textPreset: 'text-12-14' })

  return (
    <section className={cx(css.SelectedStores, className)}>
      <div className={gridStyle}>
        {title && <GoldenTitle className={css.title} title={title} />}
        <div className={css.stores}>
          {items?.map((store, index) => (
            <div className={css.store} key={`store${index}`}>
              <div className={css.content}>
                {store?.name && (
                  <RichText
                    className={cx(css.titleStore, titleStoreStyle)}
                    render={store.name?.map((name) => ({
                      ...name,
                      type: Elements.paragraph,
                    }))}
                  />
                )}
                {store?.address && (
                  <RichText
                    className={cx(css.textStore, titleStoreStyle)}
                    render={store.address}
                  />
                )}

                <div className={cx(css.contactStore, textStoreStyle)}>
                  {store?.text && (
                    <RichText
                      className={cx(css.textStore, textStoreStyle)}
                      render={store.text}
                    />
                  )}
                  {store?.email && (
                    <Link className={css.link} {...store.email} />
                  )}
                  {store?.phone && (
                    <Link className={css.link} {...store.phone} />
                  )}
                </div>
              </div>
              {store?.image && (
                <div className={css.image}>
                  <Ratio ratio={0.75}>
                    {(cn) => (
                      <ImageWithPlaceholder
                        className={cn}
                        layout="fill"
                        objectFit="cover"
                        sizesFromBreakpoints={[
                          { breakpoint: 'md', columns: 2 },
                          { columns: 2 },
                        ]}
                        {...store.image}
                        alt={
                          store?.image?.alt ??
                          prismicRichTextAsText(store?.name) ??
                          DEFAULT_ALT
                        }
                      />
                    )}
                  </Ratio>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

SelectedStores.defaultProps = {}

export default SelectedStores
