import classnames from 'classnames/bind'
import React from 'react'

import RichText, { RichTextBlock } from '~/components/RichText'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface GoldenTitleProps {
  className?: string
  title?: RichTextBlock[]
}

function GoldenTitle({ className, title }: GoldenTitleProps) {
  const titleStyle = useStyle({ textPreset: 'cta-10', color: 'gold' })

  return (
    <RichText
      className={cx(className, titleStyle, css.GoldenTitle)}
      render={title}
    />
  )
}

GoldenTitle.defaultProps = {}

export default GoldenTitle
