import useLocale from '~/hooks/useLocale'

import { getPrismicLangFromLocale } from '~/utils/locales'

function usePrismicLocale(): string {
  const locale = useLocale()
  return getPrismicLangFromLocale(locale) ?? locale
}

export default usePrismicLocale
