import CoverPush from '~/components/CoverPush'
import EditoLongImageText from '~/components/EditoLongImageText'
import EditoLongText from '~/components/EditoLongText'
import FormSlice from '~/components/FormSlice'
import HeroMain from '~/components/HeroMain'
import ImageDyptic from '~/components/ImageDyptic'
import ImageFullWidth from '~/components/ImageFullWidth'
import LargeText from '~/components/LargeText'
import ProductDypticSlider from '~/components/ProductDypticSlider'
import ProductsSlider from '~/components/ProductsSlider'
import PushLinks from '~/components/PushLinks'
import ReboundZoneEdito from '~/components/ReboundZoneEdito'
import RelatedPosts from '~/components/RelatedPosts'
import SelectedStores from '~/components/SelectedStores'
import { MapSliceTypeToComponent } from '~/components/SliceManager'
import TinyPush from '~/components/TinyPush'
import VideoPlayer from '~/components/VideoPlayerWrapper'

export const mapSliceToComponent: MapSliceTypeToComponent = {
  cover_push: CoverPush,
  edito_long_image_text: EditoLongImageText,
  edito_long_text: EditoLongText,
  form: FormSlice,
  hero_main: HeroMain,
  image_dyptic: ImageDyptic,
  image_full_width: ImageFullWidth,
  large_text: LargeText,
  products_slider: ProductsSlider,
  product_dyptic_slider: ProductDypticSlider,
  push_links: PushLinks,
  rebound_zone_edito: ReboundZoneEdito,
  related_posts: RelatedPosts,
  selected_stores: SelectedStores,
  tiny_push: TinyPush,
  video_player: VideoPlayer,
}
