import classnames from 'classnames/bind'
import React from 'react'

import ImageWithPlaceholder from '~/components/ImageWithPlaceholder'
import InlineCta from '~/components/InlineCta'
import Link from '~/components/Link'
import Ratio from '~/components/Ratio'
import RichText from '~/components/RichText'

import { useStyle } from '~/providers/StyleProvider'

import { ArticlePushProps } from '~/data/serialize-article-push'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface PostProps extends ArticlePushProps {
  className?: string
  linksLabel?: string
}

const DEFAULT_ALT = 'Post image'

function Post({
  className,
  image,
  title,
  text,
  date,
  category,
  link,
  linksLabel,
}: PostProps) {
  const categoryStyle = useStyle({ textPreset: 'text-11', color: 'gray-68' })
  const dateStyle = useStyle({ textPreset: 'title-9-11', color: 'gold' })
  const titleStyle = useStyle({ textPreset: 'text-13-18' })
  const textStyle = useStyle({ textPreset: 'text-12' })

  return (
    <div className={cx(className, css.Post)}>
      <p className={cx(dateStyle, css.date, css.hideOnSmallScreen)}>{date}</p>
      <Link {...link} className={css.image}>
        {image && (
          <Ratio ratio={0.75}>
            {(cn) => (
              <ImageWithPlaceholder
                className={cn}
                layout="fill"
                objectFit="cover"
                sizesFromBreakpoints={[
                  { breakpoint: 'md', columns: 2 },
                  { columns: 2 },
                ]}
                {...image}
                alt={image?.alt ?? DEFAULT_ALT}
              />
            )}
          </Ratio>
        )}
      </Link>
      <div className={css.content}>
        <div className={css.headline}>
          <p className={cx(dateStyle, css.date, css.hideOnLargeScreen)}>
            {date}
          </p>
          {category && (
            <RichText
              className={cx(css.category, categoryStyle)}
              render={category}
            />
          )}
        </div>
        {title && (
          <RichText className={cx(css.title, titleStyle)} render={title} />
        )}
        {text && <RichText className={cx(css.text, textStyle)} render={text} />}
        {link && (
          <InlineCta
            {...link}
            className={cx(css.hideOnSmallScreen, css.link)}
            textPreset="cta-10"
            lineProps={{ theme: 'gray', initialLineState: 'underlined' }}>
            {linksLabel}
          </InlineCta>
        )}
      </div>
    </div>
  )
}

Post.defaultProps = {}

export default Post
