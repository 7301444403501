import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslate } from 'react-polyglot'

import Input, { InputProps } from '~/components/Form/Input'

import { EMAIL_REGEX } from '~/utils/regex'

export default function Email({
  required = true,
  autoComplete = 'email',
  placeholder,
  name = 'email',
  ...rest
}: InputProps) {
  const t = useTranslate()
  const { watch } = useFormContext() || {}

  const processedPlaceholder = placeholder || t('form.email')

  const validate = () => {
    return EMAIL_REGEX.test(watch(name))
  }

  return (
    <Input
      name={name}
      type="email"
      autoComplete={autoComplete}
      autoCapitalize="none"
      required={required}
      placeholder={processedPlaceholder}
      validate={{ email: validate }}
      {...rest}
    />
  )
}
